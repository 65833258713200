import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import ProductsSlider from "~components/products/productsSlider";
import "../styles/pages/_lp.scss";
import heroImg from "../images/lp/TSD_Content_Hero.png";
import sound1Image from "../images/lp/TSD_Content_Sound_1.png";
import sound2Image from "../images/lp/TSD_Content_Sound_2.png";
import sound3Image from "../images/lp/TSD_Content_Sound_3.png";
import sound1 from "../audio/Audio-content-34.mp3";
import sound2 from "../audio/Audio-content-35.mp3";
import sound3 from "../audio/Audio-content-37.mp3";

var settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "700px",
  slidesToShow: 1,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        centerPadding: "500px",
      },
    },
    {
      breakpoint: 2000,
      settings: {
        centerPadding: "300px",
      },
    },
    {
      breakpoint: 1400,
      settings: {
        centerPadding: "100px",
      },
    },
    {
      breakpoint: 821,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
      },
    },
  ],
};

const SocialContent = () => (
  <Layout type="marketin-stuck-lp template-lp">
    <SEO
      title="Simple Social Content Packages"
      description="Strong social content is essential for engagement, discovery, and growth. Simplify your content strategy with tailored content packages from The Simple Department."
    />

    <section className="da-lp-hero website-lp-hero">
      <img src={heroImg} />
      <div className="container">
        <div className="content-wrapper">
          <h2>Content is way more than your social media calendar.</h2>
          <p>
            Content is everything you create, share and deliver around your
            business and brand. It’s what makes them believe what you are saying
            is true, it’s what makes them decide to be loyal and it’s what
            convinces them that yes, they should do that thing you are asking
            them to do.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Content_Packages" className="lp-btn red-btn">
                Ready to Educate, Entertain and Inspire?
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>
    <section className="ms-lp-slider">
      <Slider {...settings} className="symptoms-slider">
        <div className="give-padding">
          <div className="single-slide content-slide1">
            <div className="slide-content">
              <h3>The Spreadsheet Trap</h3>
              <p>
                You think if you schedule everything for the entire year you
                have a great content plan…but really all you have is a
                spreadsheet of things people are going to ignore.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide content-slide2">
            <div className="slide-content">
              <h3>Too many chefs in the kitchen.</h3>
              <p>
                Social media especially and maybe specifically – needs to be off
                the cuff, in the moment for at least 60-70% of the content that
                is being produced and put out into the world. It needs to be
                real, which means it can’t be over analysed by you, your
                bookkeeper and your hermit neighbour who scares your kids.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide content-slide3">
            <div className="slide-content">
              <h3>Tuned into the wrong channels.</h3>
              <p>
                Social media, again just one aspect of content, is about your
                audience and listening. Which means you need to be where they
                are and there is a great chance that they are spending time on
                things that you have never even think of. Discord anyone?
                Reddit?
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide content-slide4">
            <div className="slide-content">
              <h3>Thinking that content is ONLY social media.</h3>
              <p>
                So many founders and marketing “experts” have tunnel vision when
                it comes to content marketing. Content marketing is so much more
                than social media. It is how you build relationships with
                traditional media, online reviews, blogs, everything on your
                website, your in-store experience, your packaging, your menus –
                anywhere and anything that is seen, touched or heard by your
                audience is a content channel.
              </p>
            </div>
          </div>
        </div>
      </Slider>
      <div className="container narrow-container">
        <h2 className="text-center">
          Let’s make this whole thing less overwhelming.
        </h2>
        <p className="text-center spacer-1">
          Let’s organise everything into Interesting, Educational and Useful &
          Actionable and everything else starts to fall into place.
        </p>
        <p className="btn-cta-wrapper text-center">
          <strong>
            <Link to="#TSD-Content_Packages" className="lp-btn white-btn">
              Let’s make content fun again
            </Link>
          </strong>
        </p>
      </div>
    </section>
    <section className="ms-lp-action">
      <div className="container">
        <div>
          <h2>Some of our insights into content marketing</h2>
          <p>
            Here are just a few highlights from some recent case studies we have
            published that showcase how we think through, plan, launch, manage
            and scale your digital advertising operations.
          </p>
        </div>
        <div className="audio-container">
          <div className="single-audio">
            <img src={sound1Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>The Impact of Simplifying Your Content</strong>
                </figcaption>
                <p>
                  How Layering Content Complexity Across Different Channels
                  Draws People In
                </p>
              </div>
              <audio controls src={sound1}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound2Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Stress Less, Experiment More</strong>
                </figcaption>
                <p>
                  Content Is Perishable – So Take The Time To Test A Lot & Find
                  What Resonates
                </p>
              </div>
              <audio controls src={sound2}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound3Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>The 3 Formulas For Content</strong>
                </figcaption>
                <p>A Basic Pre-Flight Checklist for Content Idea Generation</p>
              </div>
              <audio controls src={sound3}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section className="website-lp-shout content-lp-shout">
      <div className="container">
        <div className="content-wrapper">
          <h2>Not really sure what else to tell you…</h2>
          <p>
            It’s like any muscle you want to make stronger – get the basic
            movements down, practice and repeat and add more as you get
            stronger, faster and better.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Content_Packages" className="lp-btn blue-btn">
                Just say yes
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>

    <section className="ms-lp-products" id="TSD-Content_Packages">
      <div className="container">
        <div className="content-wrapper">
          <h2 className="text-center">Easy ways to get started right now.</h2>
        </div>
        <ProductsSlider>
          <div
            title="SMALL CONTENT PACKAGE"
            price="$1,500"
            id="small-content-package"
            extrainfo="+ one-time $625 launch fee"
          >
            <p>
              The foundational content your brand needs to connect with your
              audience, deliver your key brand messages and nurture brand
              loyalty. This option is ideal for brands that want to grow at a
              slow and steady pace.
            </p>
          </div>
          <div
            title="MEDIUM CONTENT PACKAGE"
            price="$2,550"
            id="medium-content-package"
            extrainfo="+ one-time $625 launch fee"
          >
            <p>
              Create meaningful engagement with your audience, increase your
              chances of being found, and reach new audience segments. Ideal for
              brands that want to soft-launch a new venture.
            </p>
          </div>
          <div
            title="LARGE CONTENT PACKAGE"
            price="$3,875"
            id="large-content-package"
            extrainfo="+ one-time $625 launch fee"
          >
            <p>
              Take your social growth to the next level and turn your organic
              social channels into powerful sales and brand loyalty tools. Ideal
              for brands that are launching a new product or business.
            </p>
          </div>
        </ProductsSlider>
      </div>
    </section>
  </Layout>
);

export default SocialContent;
